.photosOption1 {

  .inner {
    max-width: calc(100vw - 550px);
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;

    .firstImage  {
      margin-top: 70px;
      width: calc(100vw - 550px);
      max-width: 1083px;
      object-fit: contain;
      margin-bottom: 1px;
    }

    .secondImage {
      width: calc((100vw - 550px)*0.35);
      height: calc((100vw - 550px) *0.50);
      margin-right: 3px;
      object-fit: cover;
      max-height: calc(1083px *0.50);
      max-width: calc(1083px *0.35);
    }

    .singleSecondImage {
      width: calc(100vw - 550px);
      margin-right: 3px;
      object-fit: cover;
    }

    .thirdImage {
      width: calc((100vw - 550px)*0.65);
      height: calc((100vw - 550px) *0.50);
      object-fit: cover;
      max-height: calc(1083px *0.50);
      max-width: calc(1083px *0.65);
    }

    .singleThirdImage {
      width: calc(100vw - 550px);
      object-fit: cover;
    }
  }

  .inner::-webkit-scrollbar {
    display: none;
  }
}

.phonePhotosOption1 {

  .inner {
    display: flex;
    flex-direction: column;

    .firstImage  {
      margin-top: 0px;
      object-fit: contain;
      margin-bottom: 1px;
      width: 100vw;
    }

    .secondImage {
      margin-right: 3px;
      object-fit: cover;
      width: 100vw;
    }

    .singleSecondImage {
      margin-right: 3px;
      object-fit: cover;
      width: 100vw;
    }

    .thirdImage {
      object-fit: cover;
      width: 100vw;
    }

    .singleThirdImage {
      object-fit: cover;
      width: 100vw;
    }
  }

  .inner::-webkit-scrollbar {
    display: none;
  }
}

.photosOption2 {
  max-width: calc(100vw - 550px);
  display: flex;
  justify-content: center;
  flex-direction: column;


  .firstImage  {
    margin-top: 70px;
    width: 1071px;
    height: 700px;
    object-fit: cover;
    margin-bottom: 3px;
  }

  .secondImage {
    width: 418px;
    height: 420px;
    margin-right: 3px;
    margin-bottom: 3px;
    object-fit: cover;
  }

  .thirdImage {
    width: 650px;
    height: 418px;
    object-fit: cover;
  }
}
