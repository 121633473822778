.projectPage {
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  transition: ease-in-out opacity 0.4s;

  .leftPart {
    margin-left: 24px;
    width: 400px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .leftPartContent {
      position: absolute;

      .arrow:hover {

        .bar {
          width: 50px;
        }
      }

      .arrow {
        margin-top: 15px;
        display: flex;
        align-items: center;
        width: 100px;

        .bar {
          height: 1.5px;
          background-color: #000000;
          width: 30px;
          transition: ease-in-out width 0.3s;
        }
      }
    }

    .agencyLogo {
      width: 300px;
      object-fit: contain;
      cursor: pointer;
    }

    .arrow {
      width: 12px;
      object-fit: contain;
      cursor: pointer;
      transition: opacity 1s ease-in-out;

    }

    .arrowReverse {
      width: 12px;
      object-fit: contain;
      transform: rotate(180deg);
      cursor: pointer;
      transition: opacity 1s ease-in-out;
    }

    .projectName {
      font-size: 22px;
      font-weight: 600;
      text-align: center;
      letter-spacing: 3px;
      margin-bottom: 0px;
      margin-left: 30px;
      margin-right: 30px;
    }

    .projectLocDate {

    }
  }

  .rightPart {
    width: calc(100vw - 550px);
    display: flex;
    overflow: hidden;
    max-width: 1083px;
    transition: opacity ease-in-out 1s;

  }
}

.phoneProjectPage {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: ease-in-out opacity 0.4s;
  min-height: 100vh;

  .leftPart {
    width: 100vw;
    align-items: center;
    height: 400px;
    display: flex;
    flex-direction: column;

    .leftPartContent {
      position: absolute;

      .arrow:hover {

        .bar {
          width: 50px;
        }
      }

      .arrow {
        margin-top: 15px;
        display: flex;
        align-items: center;
        width: 100px;
        height: 30px;

        .bar {
          height: 1.5px;
          background-color: #000000;
          width: 30px;
          transition: ease-in-out width 0.3s;
        }
      }
    }

    .agencyLogo {
      margin-top: 50px;
      width: 300px;
      object-fit: contain;
      cursor: pointer;
    }

    .arrow {
      width: 12px;
      object-fit: contain;
      cursor: pointer;
      transition: opacity 1s ease-in-out;

    }

    .arrowReverse {
      width: 12px;
      object-fit: contain;
      transform: rotate(180deg);
      cursor: pointer;
      transition: opacity 1s ease-in-out;
    }

    .projectName {
      font-size: 22px;
      font-weight: 600;
      text-align: center;
      letter-spacing: 3px;
      margin-bottom: 0px;
      margin-left: 10px;
      margin-right: 10px;
    }

    .projectLocDate {

    }
  }

  .rightPart {
    display: flex;
    overflow: hidden;
    transition: opacity ease-in-out 1s;
  }
}
