.homePage {
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  transition: ease-in-out opacity 0.4s;

  .leftPart {
    margin-left: 24px;
    min-width: 300px;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    transition: width ease-in-out 1s;

    .button:hover {
      cursor: pointer;
      .bar {
        width: 100%;
      }
    }

    .button {
      transition: all ease-in-out 1s;

      p {

        margin-bottom: 5px;
        letter-spacing: 1.3px;
      }

      .bar {
        width: 35px;
        height: 1px;
        background-color: #000000;
        transition: width 0.3s ease-in-out;
      }
    }

    .agencyLogo {
      width: 300px;
      object-fit: contain;
      margin-bottom: 25px;
    }

  }

  .picturesPart {
    transition: width ease-in-out 1s;
    height: 100vh;
    margin-left: 100px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: calc(100vw - 550px);
    overflow: hidden;

    .image1 {
      width: calc((100vw - 550px)/2.6);
      max-width: 412px;
      height: calc((100vw - 550px)/2.6);
      object-fit: cover;
      max-height: 412px;
      transition: opacity 1s ease-in-out;
    }

    .image2 {
      width: calc((100vw - 550px)/1.6);
      max-width: 669px;
      max-height: 412px;
      height: calc((100vw - 550px)/2.6);
      object-fit: cover;
      margin-left: 2px;
      transition: opacity 1s ease-in-out;
    }

    .image3 {
      width: calc((100vw - 550px)/2.6);
      max-width: 412px;
      max-height: 412px;
      height: calc((100vw - 550px)/2.6);
      object-fit: cover;
      transition: opacity 01s ease-in-out;
    }

    .image4 {
      width: calc((100vw - 550px)/1.6);
      max-width: 669px;
      max-height: 412px;
      height: calc((100vw - 550px)/2.6);
      object-fit: cover;
      margin-left: 2px;
      transition: opacity 1s ease-in-out;
    }
  }


  .rotate-and-grow {
    animation-name: rotateAndGrow;
    animation-duration: 5s; // vous pouvez ajuster cette valeur selon vos besoins
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards; // ajouté pour conserver le dernier état de l'animation
    width: 80px;
  }

  @keyframes rotateAndGrow {
    0% {
      transform: rotate(0deg) scale(1);
    }
    50% {
      transform: rotate(90deg) scale(1);
    }
    100% {
      transform: rotate(90deg) scale(1.3);
    }
  }


}

.littleHomePage {
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  transition: ease-in-out opacity 0.4s;
  flex-direction: column;

  .leftPart {
    width: 100%;
    margin-bottom: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: width ease-in-out 1s;

    .button {
      transition: opacity ease-in-out 1s;
      -webkit-tap-highlight-color: rgba(0,0,0,0);
      outline: none;
      border: none;

      p {

        margin-bottom: 5px;
        letter-spacing: 1.3px;
      }

      .bar {
        width: 35px;
        height: 1px;
        background-color: #000000;
        transition: width 0.3s ease-in-out;
      }
    }

    .agencyLogo {
      width: 300px;
      object-fit: contain;
      margin-bottom: 25px;
    }

  }

  .picturesPart {
    transition: width ease-in-out 1s;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    .image1 {
      width: calc((100vw * 0.35));
      height: calc((100vw * 0.35));
      object-fit: cover;
      transition: opacity 1s ease-in-out;
    }

    .image2 {
      width: calc((100vw * 0.55));
      height: calc((100vw * 0.35));
      object-fit: cover;
      margin-left: 2px;
      transition: opacity 1s ease-in-out;
    }

    .image3 {
      width: calc((100vw * 0.35));
      height: calc((100vw * 0.35));
      object-fit: cover;
      transition: opacity 01s ease-in-out;
    }

    .image4 {
      width: calc((100vw * 0.55));
      height: calc((100vw * 0.35));
      object-fit: cover;
      margin-left: 2px;
      transition: opacity 1s ease-in-out;
    }
  }


  .rotate-and-grow {
    animation-name: rotateAndGrow;
    animation-duration: 5s; // vous pouvez ajuster cette valeur selon vos besoins
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards; // ajouté pour conserver le dernier état de l'animation
    width: 80px;
  }

  @keyframes rotateAndGrow {
    0% {
      transform: rotate(0deg) scale(1);
    }
    50% {
      transform: rotate(90deg) scale(1);
    }
    100% {
      transform: rotate(90deg) scale(1.3);
    }
  }


}
