$font-1: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif;

$color-1: #4B5FB0;
$color-2: #61dafb;
$color-2: #FFFFFF;
$color-3: #282c34;
$color-4: rgb(32, 35, 42);
$color-5: #000000;

body {
  margin: 0rem;
  font-family: $font-1;
  color: $color-3;
  background-color: #FFFFFF;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: 500;
  word-wrap: break-word;

  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.page {
  flex: 1;
  height: 100vh;
  overflow: hidden;
  width: 100vw;
}

.phonePage {
  flex: 1;
}

.transition {
  transition: all  ease-in-out 0.2s;
}

.transitionLongue {
  transition: all  ease-in-out 1s;

}

.hover:hover {
  opacity: 0.7;
  cursor: pointer;
}

.hover {
  transition: opacity 0.2s ease-in-out;
  opacity: 1;
}
