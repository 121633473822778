.footer {
  width: 70vw;
  background-color: #000000;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  right: 0;
  overflow: hidden;
  transition: width ease 0.4s;

  .footerPart {
    width: calc(70vw / 2.5);

    .title {
      font-size: 16px;
      font-weight: 600;
      color: #FFFFFF;
    }

    .subtitle {
      font-size: 13px;
      color: #FFFFFF;

    }
  }

  .linkLegals {
    color: #FFFFFF;
    font-size: 11px;
    font-weight: 600;
    margin-left: 5px;
  }

  .linkLegalsTitle {
    color: #FFFFFF;
    font-size: 11px;
    font-weight: 600;
    margin-left: 5px;
    cursor: pointer;
  }

}

.phoneFooter {
  width: 100vw;
  background-color: #000000;
  display: flex;
  align-items: center;
  flex-direction: column;
  right: 0;
  overflow: hidden;
  transition: width ease 0.4s;

  .footerPart {

    .title {
      font-size: 16px;
      font-weight: 600;
      color: #FFFFFF;
    }

    .subtitle {
      font-size: 13px;
      color: #FFFFFF;

    }
  }

  .linkLegals {
    color: #FFFFFF;
    font-size: 11px;
    font-weight: 600;
    margin-left: 5px;
  }

  .linkLegalsTitle {
    color: #FFFFFF;
    font-size: 11px;
    font-weight: 600;
    margin-left: 5px;
    cursor: pointer;
  }

}

